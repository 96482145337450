import React, { Component } from "react";
import TweetContainer from "./TweetContainer";
import FilterBox from "./FilterBox";
import { Drawer, Button } from "@material-ui/core";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Container, Row, Col, Visible } from "react-grid-system";
// import DropdownButton from 'react-bootstrap/DropdownButton';
import { getDatabase, ref, onValue } from "firebase/database";
import "bootstrap/dist/css/bootstrap.css";
import "intersection-observer";
import { ScrollView } from "@cantonjs/react-scroll-view";

const statesRef = require("../States.json");
const predefinedKeywords = [
  "water",
  "rain",
  "wildfire",
  "weather",
  "climate",
  "farmer",
  "heat",
  "crop",
];

function getFilterlist(data) {
  let tagList = new Set();
  let tagCount = {};
  const filterStatus = [];
  let idList = new Set();
  // console.log("printing id list",idList)
  // const newData = [...data]
  const newData = [];

  for (const tweet of data) {
    if (tweet === undefined)
      continue;

    if (!idList.has(tweet.id)) {
      idList.add(tweet.id);
      newData.push(tweet);

      const keywordInfo = [];
      for (const keyword of predefinedKeywords) {
        if (tweet.text.toLowerCase().includes(keyword)) {
          keywordInfo.push(keyword);
          if (tagList.has(keyword)) {
            tagCount[keyword]++;
          } else {
            tagList.add(keyword);
            filterStatus.push({
              category: "keyword",
              tag: keyword,
              checked: 0,
            });
            tagCount[keyword] = 1;
          }
        }

      }
      tweet["keyword"] = keywordInfo;

      const location = { state: "", city: "" };
      if (tweet.place && tweet.place.country_code === "US") {
        if (tweet.place.place_type === "city") {
          const name = tweet.place.full_name.split(",");
          location["city"] = name[0].trim();
          location["state"] = statesRef[name[1].trim()];
        } else if (tweet.place.place_type === "admin") {
          location["state"] = tweet.place.name;
        } else {
          location["city"] = "other";
          location["state"] = "other";
        }

        if (tagList.has(location["state"])) {
          tagCount[location["state"]]++;
          // tagCount[tweet.location.state].push(tweet)
        } else {
          tagList.add(location["state"]);
          filterStatus.push({
            category: "state",
            tag: location["state"],
            checked: 0,
          });
          tagCount[location["state"]] = 1;
          // tagCount[tweet.location.state] = [tweet]
        }

        if (location["city"] && tagList.has(location["city"])) {
          tagCount[location["city"]]++;
        } else if (location["city"]) {
          tagList.add(location["city"]);
          filterStatus.push({
            category: "city",
            tag: location["city"],
            checked: 0,
          });
          tagCount[location["city"]] = 1;
        }
      }
      tweet["location"] = location;

    }
  }
  for (let filter of filterStatus) {
    filter.count = tagCount[filter.tag];
  }
  filterStatus.sort((a, b) => b.count - a.count);

  // console.log(newData.length);
  return { newData, filterStatus };
}

const filterLabel = ["keyword", "state", "city"];

class SocialMedia extends Component {
  constructor() {
    super();
    this.state = {
      initialData: [],
      initialFIlter: [],
      tweets: [],
      filterStatus: [],
      checkedItems: [],
      searchInput: {
        keyword: "",
        state: "",
        city: "",
      },

      openDrawer: false,
    };

    this.showFilteredResults = this.showFilteredResults.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
    this.handleSearchTweets = this.handleSearchTweets.bind(this);
    // this.handleLocInput = this.handleLocInput.bind(this)
  }

  componentDidMount() {
    // get tweets data from firebase
    const db = getDatabase();
    const tweetData = ref(db, "tweets/web");
  
    onValue(
      tweetData,
      (snapshot) => {
        const tweetsArray = snapshot.val();
        const data = [
          ...tweetsArray.with_loca.slice(0, 2000),
          ...tweetsArray.without_loca.slice(0, 500),
        ];
        data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        // unique.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        const { newData, filterStatus } = getFilterlist(data);    
        // console.log(newData);
        
        this.setState({
          initialData: newData,
          initialFIlter: filterStatus,
          tweets: newData,
          filterStatus: filterStatus,
        });
      },
      {
        onlyOnce: true,
      }
    );
  }

  // componentDidUpdate() {
  //   // console.log('componentDidUpdate',this.state)
  // }

  showFilteredResults(filters) {
    let filteredResults = [];
    let newFilterStatus = [];
    const tweetInfo = this.state.initialData;

    for (let filter of filters) {
      let filteredTweets = [];
      if (filter.category === "keyword") {
        filteredTweets = tweetInfo.filter((tweet) =>
          tweet[filter.category].includes(filter.tag)
        );
      } else {
        filteredTweets = tweetInfo.filter(
          (tweet) => tweet.location[filter.category] === filter.tag
        );
      }

      if (filter.checked === 1) {
        newFilterStatus.push({ ...filter, count: filteredTweets.length });
        filteredResults = [...filteredResults, ...filteredTweets];
      } else {
        newFilterStatus.push({ ...filter, count: filteredTweets });
      }
    }

    for (let filter of newFilterStatus) {
      if (filter.checked === 0) {
        let countIncludes = 0;
        for (const dataItem of filter.count) {
          if (filteredResults.includes(dataItem)) {
            countIncludes++;
          }
        }
        filter.count = countIncludes;
      }
    }

    const uniqueTweets = [...new Set(filteredResults)];

    this.setState({
      tweets: uniqueTweets,
      filterStatus: newFilterStatus,
    });
    // }
  }

  handleFilters(filterItem) {
    const value = filterItem.tag;
    const newChecked = [...this.state.checkedItems];

    const newFilterStatus = [...this.state.filterStatus];
    const updateFilterIndex = newFilterStatus.indexOf(filterItem);
    const updateIndex = newChecked.indexOf(value);
    if (updateIndex === -1) {
      newChecked.push(value);
      newFilterStatus[updateFilterIndex] = { ...filterItem, checked: 1 };
    } else {
      newChecked.splice(updateIndex, 1);
      newFilterStatus[updateFilterIndex].checked = 0;
     
    }

    this.setState({
      checkedItems: newChecked,
    });

    if (newChecked.length === 0) {
      this.handleReset();
    } else {
      this.showFilteredResults(newFilterStatus);
    }


  }

  handleSearchTweets(searchInput) {
    const tweetInfo = this.state.initialData;
    const searchResults = tweetInfo.filter((tweet) =>
      tweet.text.toLowerCase().includes(searchInput)
    );
    const newStatus = getFilterlist(searchResults);
    if (searchInput !== null) {
      this.setState({
        tweets: newStatus.newData,
        filterStatus: newStatus.filterStatus,
        searchInput: { keyword: searchInput },
      });
    } else {
      this.handleReset();
    }
  }

  // handleLocInput(category, searchInput) {

  //   const newInput = {...this.state.searchInput }
  //   newInput[category] = searchInput

  //   if ( searchInput !== null ) {
  //     this.setState( {
  //       searchInput: newInput
  //     })
  //   }
  // }

  handleReset() {
    const tweetInfo = this.state.initialData;
    const filter = this.state.initialFIlter;

    this.setState({
      tweets: tweetInfo,
      filterStatus: filter,
      checkedItems: [],
      searchInput: {
        keyword: "",
        state: "",
        city: "",
      },
    });
  }

  setOpenDrawer(open) {
    this.setState({
      openDrawer: open,
    });
  }

  render() {
    const { tweets } = this.state; // assuming your tweets data is stored in state
    const tweetIds = tweets.map(tweet => tweet.id);
    return (
      <div
        className="container fluid"
        style={{ fontFamily: "Noto Sans", paddingTop: 20 }}
      >
        <Container fluid style={{ fontFamily: "Noto Sans", paddingTop: 30 }}>
          <Row style={{ paddingLeft: 10 }}>
            <Col md={12} style={{ textAlign: "left" }}>
              <h2 className="social-media-text">Social Media</h2>

              {/* <p>Some description here.</p> */}
              {/* Mobile View */}
              <Visible xs sm md>
                <Row
                  className="search-page"
                  style={{ paddingTop: 20 }}
                  direction="column"
                >
                  <Col sm={12} md={3}>
                    <Drawer
                      anchor="right"
                      open={this.state.openDrawer}
                      onClose={() => this.setOpenDrawer(false)}
                    >
                      <div
                        className="row search-filters-title"
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 20,
                        }}
                      >
                        <div className="col" align="left">
                          <h5>Filter by</h5>
                        </div>
                        <div className="col" align="right">
                          <button
                            onClick={() => this.handleReset()}
                            className="btn btn-secondary btn-sm"
                          >
                            Reset
                          </button>
                        </div>
                      </div>

                      <Col>
                        {filterLabel.map((label, index) => (
                          <div
                            key={index}
                            className="search-filter"
                            style={{ marginTop: 20 }}
                          >
                            <FilterBox
                              handleFilters={(filters) =>
                                this.handleFilters(filters)
                              }
                              id={label}
                              filterInfo={this.state.filterStatus}
                              searchInput={this.state.searchInput}
                              handleKeywordSearch={(searchInput) =>
                                this.handleSearchTweets(searchInput)
                              }
                              // handleLocSearch={(label, searchInput) => this.handleLocInput(label, searchInput)}
                            />
                          </div>
                        ))}
                      </Col>
                    </Drawer>
                    <Button
                      variant="outlined"
                      startIcon={<FilterAltIcon />}
                      onClick={() => this.setOpenDrawer(!this.state.openDrawer)}
                    >
                      Filter
                    </Button>
                  </Col>
                </Row>
              </Visible>

              {/* DeskTop View */}
              <Visible lg xl xxl>
                <Row className="search-page" style={{ paddingTop: 20 }}>
                  <Col sm={6} md={2} align="left">
                    <h4 style={{ margin: 0 }}>Filter by</h4>
                  </Col>
                  <Col sm={6} md={1} style={{ paddingRight: 20 }} align="right">
                    <button
                      onClick={() => this.handleReset()}
                      style={{ padding: 6 }}
                      className="btn btn-secondary"
                    >
                      Reset
                    </button>
                  </Col>
                  {/* <Col align="right">{dateSelector}</Col> */}
                </Row>
              </Visible>

              <Row style={{ paddingBottom: 30 }}>
                <Visible lg xl xxl>
                  <Col sm={12} md={3}>
                    {filterLabel.map((label, index) => (
                      <div
                        key={index}
                        className="search-filter"
                        style={{ marginTop: 25 }}
                      >
                        <FilterBox
                          handleFilters={(filters) =>
                            this.handleFilters(filters)
                          }
                          id={label}
                          filterInfo={this.state.filterStatus.filter(
                            (item) => item.category === label
                          )}
                          searchInput={this.state.searchInput}
                          handleKeywordSearch={(searchInput) =>
                            this.handleSearchTweets(searchInput)
                          }
                          // handleLocSearch={(label, searchInput) => this.handleLocInput(label, searchInput)}
                        />
                      </div>
                    ))}
                  </Col>
                </Visible>
                

                <Col fluid sm={12} md={9} style={{ paddingTop: 15 }}>
                  {this.state.tweets.length === 0 &&
                  this.state.searchInput.keyword ? (
                    <div className="tweetContainer">
                      <p>No data found.</p>
                    </div>
                  ) : (
                      <ScrollView style={{ height: '1430px'}}>
                        <TweetContainer data={tweetIds} />
                      </ScrollView>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default SocialMedia;