import React, { Component } from "react";
// import map from "./20220906_usdm.png";
import "bootstrap/dist/css/bootstrap.css";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase.config";

class Home extends Component {
    constructor() {
        super();
        this.state = {
            usdm: "",
        };

        this.getImage("usdm");
    }

    getImage(image) {
        let { state } = this;
        const imagesRef = ref(storage, "Web_media/usdm_home.png");

        getDownloadURL(imagesRef).then((url) => {
            state[image] = url;
            console.log("state[image]", url);
            this.setState(state);
        });
    }

    routeChangeWeb = () => {
        this.props.setValue(1);
        this.props.history.push("/weblink");
    };

    routeChangeSocialmedia = () => {
        this.props.setValue(2);
        this.props.history.push("/socialmedia");
    };

    routeChangeHighLight = () => {
        this.props.setValue(3);
        this.props.history.push("/insights");
    };

    routeChangeNewHighLight = () => {
        this.props.setValue(4);
        this.props.history.push("/highlights");
    };

    routeChangeDisplay = () => {
        this.props.setValue(5);
        this.props.history.push("/display");
    };

    routeChangeInput = () => {
        this.props.setValue(6);
        this.props.history.push("/userinput");
    };

    render() {
        // const { titleForSearch, tutorials, currentTutorial, currentIndex } = this.state;
        return (
            <div className="container fluid" style={{ fontFamily: "Noto Sans", textAlign: 'center'}}>
                <p style={{ paddingTop: 30 }}></p>
                <h4 align="left">
                    Welcome to Dialogue and Information Platform for a Drought Event
                    (DIP-Drought)!
                </h4>
                <p align="left">
                    The US Southwest drought started in the summer of 2020 and has
                    expanded over time with growing intensity and over space to
                    northwestern and midwestern states, as shown by US Drought Monitor{" "}
                    <a href="https://droughtmonitor.unl.edu/">
                        https://droughtmonitor.unl.edu/
                    </a>{" "}
                    during the past two years.
                </p>
            
                <img
                    id="usdm"
                    src={this.state.usdm}
                    style={{maxHeight: '400px', paddingBottom: 15}}
                    className='img-fluid shadow-4'
                />

                <p align="left">
                    This mega-drought is probably the worst in the recent history of the
                    western region, and it has already severely affected agriculture,
                    water supply, hydropower, environment and ecosystems, and local and
                    regional economy in a number of states.
                </p>
                <p align="left">In DIP-Drought, you can</p>
                <ul align="left">
                    <li>
                        Find nearly 1000 articles{" "}
                        <a
                            onClick={() => {
                                this.routeChangeWeb();
                            }}
                        >
                            here
                        </a>{" "}
                        about the drought status and impacts published by national, regional
                        and local news media updated over time.
                    </li>
                    <li>
                        Share your observation, damage estimate, and opinion{" "}
                        <a
                            href=""
                            onClick={() => {
                                this.routeChangeInput();
                            }}
                        >
                            here
                        </a>
                        ; while read the inputs from others{" "}
                        <a
                            href=""
                            onClick={() => {
                                this.routeChangeDisplay();
                            }}
                        >
                            here
                        </a>
                        .
                    </li>
                    <li>
                        Review major topics and messages from{" "}
                        <a
                            href=""
                            onClick={() => {
                                this.routeChangeSocialmedia();
                            }}
                        >
                            social media
                        </a>{" "}
                        and some{" "}
                        <a
                            href=""
                            onClick={() => {
                                this.routeChangeNewHighLight();
                            }}
                        >
                            highlights
                        </a>
                        .
                    </li>
                </ul>
                <h4 align="left">Contact Us</h4>
                <p align="left">
                    Please contact the deep drought department team at University of
                    Illinois Urbana-Champaign.
                </p>
                <ul align="left">
                    <li>
                        Dr. Ximing Cai:{" "}
                        <a href="mailto: xmcai@illinois.edu">xmcai@illinois.edu</a>
                    </li>
                    <li>
                        Dr. Dong Wang:{" "}
                        <a href="mailto: dwang24@illinois.edu">dwang24@illinois.edu</a>
                    </li>
                </ul>
            </div>
        );
    }
}
export default Home;
