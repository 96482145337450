import React, { useRef, useEffect } from "react";

const { tableau } = window;

function TableauWordCloud() {
  const ref = useRef(null);
  const url =
    "https://public.tableau.com/views/WordCloudforDroughtAnalysis/Sheet1?:language=en-US&:display_count=n&:origin=viz_share_link";

  const initViz = () => {
    new tableau.Viz(ref.current, url, {
      width: "100%",
      height: "85vh",
    });
  };

  useEffect(initViz, []);

  return <div ref={ref} />;
}

export default TableauWordCloud;
