import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';

const WebLink = () => {
  const [links, setLinks] = useState([]);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetch('/ListOfArticles.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setLinks(data);
        setArticles(data); 
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const selectArticles = (e) => {
    const category = e.target.value;
    setArticles(category === "" ? links : links.filter(article => article.Category === category));
  };

  if (links.length === 0) {
    return <div>Loading...</div>;
  }

  const articlesList = articles.map((article, index) => (
    <ul key={index}>
      <li>
        <a href={article.URL} target="_blank" rel="noopener noreferrer">
          {article["Article Name"]} {/* Render specific properties */}
        </a>
      </li>
    </ul>
  ));
  
    return (
      <div className="container" style={{ fontFamily: 'Noto Sans' }}>
      <p style={{ paddingTop: 30 }}></p>
        <h4 align="left">Articles</h4>
        <p align="left">
          Nearly 1000 articles about the drought forecast, status, and various
          impacts in a number of states have been published by national,
          regional and local news media starting from May 2020. Choose a
          category of the article below.
        </p>
        <div className="row">
          <div className="col-4">
            <div className="list-group">
              <button
                value={""}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                All
              </button>
              <button
                value={"Strategy"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Strategy
              </button>
              <button
                value={"Status"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Status
              </button>
              <button
                value={"Technology"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Technology
              </button>
              <button
                value={"Water Supply/use"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Water Supply/use
              </button>
              <button
                value={"Science"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Science
              </button>
              <button
                value={"Politics"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Politics
              </button>
              <button
                value={"Policy"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Policy
              </button>
              <button
                value={"Overview"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Overview
              </button>
              <button
                value={"Market"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Market
              </button>
              <button
                value={"Livestock"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Livestock
              </button>
              <button
                value={"Life"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Life
              </button>
              <button
                value={"Lessons"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Lessons
              </button>
              <button
                value={"Investment"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Investment
              </button>
              <button
                value={"Innovation"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Innovation
              </button>
              <button
                value={"Farming"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Farming
              </button>
              <button
                value={"Environment"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Environment
              </button>
              <button
                value={"Energy"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Energy
              </button>
              <button
                value={"Ecosystems"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Ecosystems
              </button>
              <button
                value={"Crisis"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Crisis
              </button>
              <button
                value={"Business"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Business
              </button>
              <button
                value={"Behaviour"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Behaviour
              </button>
              <button
                value={"Agriculture"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Agriculture
              </button>
              <button
                value={"Action"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Action
              </button>
              <button
                value={"Image"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Image
              </button>
              <button
                value={"Video"}
                onClick={selectArticles}
                type="button"
                className="list-group-item list-group-item-action"
              >
                Video
              </button>
            </div>
          </div>
          <div className="col-8">
            <div
              style={{
                flex: 2,
                marginLeft: 20,
                marginRight: 20,
                textAlign: "left",
              }}
            >
            {articlesList}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >

        </div>
      </div>
    );
  }

export default WebLink;
