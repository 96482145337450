
//new code    
    import React, { Component } from "react";
    import "bootstrap/dist/css/bootstrap.css";
    import { Button, TextField } from "@material-ui/core";
    import TweetContainer from "./TweetContainer";
    import ArticlePreviewer from "./ArticlePreviewer";
    import { Row, Col } from "react-grid-system";
    import "intersection-observer";
    import { ScrollView } from "@cantonjs/react-scroll-view";
    import axios from "axios";
    import { CircularProgress } from "@material-ui/core";

    class Insights extends Component {
    constructor() {
        super();
        this.state = {
        tweet: [],
        keyword: "",
        article: [],
        // sentimentVis: "",
        wordCloudVis: "",
        showResults: false,
        loading: false, // Add a loading state
        error: "", // Add an error state
        };
        this.selectTopic = this.selectTopic.bind(this);
        this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
    }

        // TODO: Filter articles using the provided topics
        selectTopic(e) {
            if (e.target.innerHTML !== "") {
                this.setState({
                    

                    keyword: e.target.value,
                    
                });
            }
        }

        // TODO: Retrieve the event object and get its value, update the keyword in this.state
        handleChangeKeyword(e) {
            const userInput = e.target.value;
            this.setState({
            keyword: userInput,
            });
                }
        
        //get the tweet id from the backend go
        getTweetIDs = async () => {
            try {
                this.setState({ loading: true, error: "" });
        
                // console.log("Sending request to Go server with keyword:", this.state.keyword);
        
                const response = await axios.get(`https://sslabgpu2.ischool.illinois.edu/application/tweets/${this.state.keyword}`);
                console.log(response.data);
                const tweetIDs = response.data.map(tweet => tweet.id);

                this.setState({
                    tweet: tweetIDs,
                    showResults: true,
                    loading: false,
                });
        
                // console.log("Updated state:", this.state);
            } catch (error) {
                console.error("Error fetching tweet IDs: ", error);
                this.setState({
                    error: 'There was an error fetching tweet IDs from the server.',
                    loading: false,
                });
            }
        }
        
        getWordCloud = async () => {
            const wordCloudURL = `https://sslabgpu2.ischool.illinois.edu/application/wordcloud/${this.state.keyword}/`;
    
            try {
                let response = await axios.get(wordCloudURL, { responseType: 'blob' });

                const imageObjectURL = URL.createObjectURL(response.data);
                this.setState({
                    wordCloudVis: imageObjectURL,
                    loading: false,
                });
            } catch (error) {
                console.error("Error fetching or generating word cloud: ", error);
                this.setState({
                    error: 'There was an error fetching or generating the word cloud.',
                    loading: false,
                });
            }
        }
    

        getArticles = () => {
            const keyword = this.state.keyword.toLowerCase();
          
            fetch('/ListOfArticles.json')
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              })
              .then(data => {
                const filteredArticles = data.filter(article =>
                  article['Article Name'].toLowerCase().includes(keyword) ||
                  article['Category'].toLowerCase().includes(keyword)
                );
          
                this.setState({
                  article: filteredArticles,
                  showResults: true,
                });
              })
              .catch(error => console.error('Error fetching articles:', error));
          };
          
        


        componentDidUpdate() {
        // console.log(this.state);
        localStorage.setItem("formData", JSON.stringify(this.state));
        }


        searchResults = () => {
            return (
                <div className="container" style={{ justifyContent: 'center' }}>
                    {/* TODO: Visualize the keyword-related articles */}
                    <p style={{ paddingTop: 30 }}></p>
                    <h2 align="left">Articles related to your Topic</h2>
                    <p align="left">
                        Here are some articles for you to view
                    </p>
                    {/* uncomment later */}
                    <div style={{ marginTop: 50 }}>
        {this.state.article.length === 0 ? (
          <div>
            {this.state.loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <CircularProgress />
              </div>
            ) : (
              <p>No articles found related to your topic.</p> // Display this message when no articles are found
            )}
          </div>
        ) : (
          <ArticlePreviewer data={this.state.article} limit={18} />
        )}
                    </div>


                    {/* TODO: Visualize the keyword-related tweets */}
                    <p style={{ paddingTop: 50 }}></p>
                    <h2 align="left">Tweets related to your Topic</h2>
                    <p align="left">
                        Here are some tweets related to your topic
                    </p>
                    <div id="first-tweet">
    {this.state.loading ? (
        <div className="tweetContainer">
            {this.state.loading && (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
        <CircularProgress />
    </div>
)}

        </div>
    ) : (
        this.state.tweet.length === 0 ? (
            <div className="tweetContainer">
                {this.state.loading && (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
        <CircularProgress />
    </div>
)}

            </div>
        ) : (
            <ScrollView style={{ height: '200vh' }}>
                <TweetContainer data={this.state.tweet} />
            </ScrollView>
        )
    )}
</div>



                    {/* TODO: Visualize the keyword-related visualization */}
                    <p style={{ paddingTop: 50 }}></p>
                    <h2 align="left">Visualization related to your Topic</h2>
                    <p align="left">
                        Here is the visualization
                    </p>
                    <Row style={{ paddingLeft: 30 }}>
                        <Col>
  
                            <br /><br />
                            {this.state.wordCloudVis === "" ? (
                                <div className="tweetContainer">
                                    {this.state.loading && (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
        <CircularProgress />
    </div>
)}

                                </div>
                            ) :
                                <div id="second-vis">
                                    <img src={this.state.wordCloudVis} 
                                        alt="image/png" 
                                        className='img-fluid shadow-4'/>
                                </div>
                            }
                        </Col>
                    </Row>
                </div>
            )
        }
        handleClick = async () => {
            this.setState({
              tweet: [],
              article: [],
              wordCloudVis: "",
              showResults: false,
              error: "",
              loading: true, // Start loading
            });
          
            try {
              // Fetch articles first
              this.getArticles();
          
              // Then, try to get tweets
              await this.getTweetIDs();
          
              // If successful, try to get the word cloud
              await this.getWordCloud();
          
              // Set the state to show results
              this.setState({ showResults: true, loading: false });
            } catch (error) {
              // If there's an error in either getTweetIDs or getWordCloud
              console.error("Error in fetching data: ", error);
              this.setState({
                error: 'There was an error fetching data.',
                loading: false,
              });
            }
          }
          
        render() {
            return (
                <div className="container fluid" style={{ fontFamily: "Noto Sans", alignItems: "center" }}>
                    <p style={{ paddingTop: 30 }}></p>
                    <h2 align="left">Topics to Select</h2>
                    <p align="left">
                        Here you can select either one of the ten topics to start exploring our data analytics.
                    </p>
                    <div style={{ paddingTop: 15 }}>
                        {/* <div className="selections"> */}
                            <div id="selections" className="list-group list-group-horizontal flex-fill" style={{ justifyContent: "center" }}>
                                <button
                                    value="drought"
                                    onClick={this.selectTopic}
                                    type="button"
                                    className="list-group-item list-group-item-action"
                                    aria-current="true"
                                >
                                    Drought
                                </button>
                                <button
                                    value="rainfall"
                                    onClick={this.selectTopic}
                                    type="button"
                                    className="list-group-item list-group-item-action"
                                >
                                    Rainfall
                                </button>
                                <button
                                    value="wildfire"
                                    onClick={this.selectTopic}
                                    type="button"
                                    className="list-group-item list-group-item-action"
                                >
                                    Wildfire
                                </button>
                                <button
                                    value="famine"
                                    onClick={this.selectTopic}
                                    type="button"
                                    className="list-group-item list-group-item-action"
                                >
                                    Famine
                                </button>
                                <button
                                    value="groundwater"
                                    onClick={this.selectTopic}
                                    type="button"
                                    className="list-group-item list-group-item-action"
                                >
                                    Groundwater
                                </button>
                                <button
                                    value="wetland"
                                    onClick={this.selectTopic}
                                    type="button"
                                    className="list-group-item list-group-item-action"
                                >
                                    Wetland
                                </button>
                            </div>
                        {/* </div> */}

                        {/* TODO: Get the user-defined keyword and use it to retrieve articles and tweets */}
                        <div style={{ marginTop: 40, marginBottom: 40, justifyContent: "center", alignContent: "center" }}>
                            <div style={{
                                zIndex: 10,
                                background: 'white',
                                position: 'sticky',
                                top: 1
                            }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    label="Keyword"
                                    variant="outlined"
                                    placeholder="Input any topic you want"
                                    value={this.state.keyword}
                                    onChange={this.handleChangeKeyword}
                                />
                                &nbsp; &nbsp; &nbsp;
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={this.handleClick}
                                    style={{ marginTop: 5 }}
                                >
                                    Submit
                                </Button>
                            </div>
                            {this.state.showResults ?
                                <this.searchResults /> :
                                null
                            }
                        </div>
                    </div>
                </div>
            );
        }
    }


    export default Insights;
