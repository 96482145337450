import React, { useEffect, useState, useMemo } from "react";
import { Card, CardActionArea, CardMedia, CardContent, Typography, Box, CircularProgress } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "../articlepreview.css";
import Skeleton from '@mui/material/Skeleton';

function ArticleContent(props) {
  const [imgUrl, setImgUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  const url = props.url;
  const title = props.name;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const apiUrl = `https://sslabgpu2.ischool.illinois.edu/application/get_image_url/?url=${url}`;

    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        setImgUrl(data.img_url);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error:', error);
        setLoading(false);
      });
  }, [url]);

  const truncatedTitle = useMemo(() => title.length > 50 ? `${title.substring(0, 47)}...` : title, [title]);

  return (
    <Box width={isSmallScreen ? '100%' : 'auto'} marginBottom={2}>
      <Card>
        <CardActionArea href={url} target="_blank" rel="noopener noreferrer">
          {loading ? (
            <Box position="relative" width="100%" height={isSmallScreen ? 150 : 200}>
              <Skeleton variant="rectangular" width="100%" height="100%" />
              <CircularProgress
                style={{
                  position: 'absolute',
                  top: '45%',
                  left: '45%',
                  transform: 'translate(-50%, -50%)'
                }}
              />
            </Box>
          ) : (
            <CardMedia
              component="img"
              height={isSmallScreen ? 150 : 200}
              image={imgUrl || 'default_placeholder_image_url'}
              alt="Preview"
              onLoad={() => setLoading(false)}
              onError={() => setLoading(false)}
            />
          )}
          <CardContent>
            <Typography gutterBottom variant="subtitle1" component="div" noWrap>
              {truncatedTitle}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export default React.memo(ArticleContent);
