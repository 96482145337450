import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Container, Row, Col, Visible } from "react-grid-system";
// import articles from "../ListOfArticles.json";
import ArticleContent from "./ArticleContent";


function ArticlePreviewer(props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [articleData, setArticleData] = useState([]);

  useEffect(() => {
    
    setArticleData(props.data.slice(0, props.limit));
  }, [props.data]);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };
  const itemsPerPage = 6;
  const offset = currentPage * itemsPerPage;
  const articleCol1 = [];
  const articleCol2 = [];
  const articleCol3 = [];

  articleData.slice(offset, offset + itemsPerPage).forEach((article, index) => {
    if (index % 3 === 0) {
      articleCol1.push(article);
    } else if (index % 3 === 1) {
      articleCol2.push(article);
    } else {
      articleCol3.push(article);
    }
  });

  const currentPageData = (
    <Container>
      <Row style={{ justifyContent: "spaceEvenly", flexWrap: "wrap", justifyContent: "center"}}>
        <Col md={12} lg={4} xl={4}>
        {articleCol1 &&
  articleCol1.map((article, index) => (
    <ArticleContent 
      key={`${article.URL}-${currentPage}`} 
      url={article.URL} 
      name={article['Article Name']}
    />
))}

        </Col>
        <Col md={12} lg={4} xl={4}>
        {articleCol2 &&
  articleCol2.map((article, index) => (
    <ArticleContent 
      key={`${article.URL}-${currentPage}`} 
      url={article.URL} 
      name={article['Article Name']}
    />
))}

        </Col>
        <Col md={12} lg={4} xl={4}>
        {articleCol3 &&
  articleCol3.map((article, index) => (
    <ArticleContent 
      key={`${article.URL}-${currentPage}`} 
      url={article.URL} 
      name={article['Article Name']}
    />
))}

        </Col>
      </Row>
    </Container>
  );

  const pageCount = Math.ceil(articleData.length / itemsPerPage);

  return (
    <div className="tweetContainer justify-content-center">
      {currentPageData}
      <div className="tweets-footer" style={{ paddingTop: 30 }}>
        <div className="row" style={{ margin: 0, justifyContent: "center" }}>
          <Visible lg xl xxl>
            <ReactPaginate
              className="pagination justify-content-center"
              pageClassName="page-item"
              previousClassNamee="page-item"
              nextClassNamee="page-item"
              breakClassName="page-item"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              pageLinkClassName="page-link"
              activeClassName="active"
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={3}
              pageCount={pageCount}
              previousLabel="< "
              renderOnZeroPageCount={null}
              hrefBuilder={(page, pageCount, selected) =>
                page >= 1 && page <= pageCount ? `/page/${page}` : "#"
              }
              hrefAllControls
            />
          </Visible>
          <Visible xs sm md>
            <ReactPaginate
              className="pagination justify-content-center"
              pageClassName="page-item"
              previousClassNamee="page-item"
              nextClassNamee="page-item"
              breakClassName="page-item"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              pageLinkClassName="page-link"
              activeClassName="active"
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              marginPagesDisplayed={currentPage > 2 ? 1 : 2}
              pageCount={pageCount}
              previousLabel="< "
              renderOnZeroPageCount={null}
            />
          </Visible>
        </div>
      </div>
    </div>
  );
}

export default ArticlePreviewer;
