import React, { useState, useEffect } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Checkbox } from "@mui/material";
import { FaSearch } from "react-icons/fa";

function FilterBox(props) {
  const [filterList, setFilterList] = useState([]);
  const [locationResult, setLocationResult] = useState([]);

  useEffect(() => {
    // const list = props.filterInfo.filter( item => item.category  === props.id)
    setFilterList(props.filterInfo);
    setLocationResult(props.filterInfo);
  }, [props.filterInfo]);

  const [keywordInput, setKeywordInput] = useState("");
  useEffect(() => {
    setKeywordInput(props.searchInput.keyword);
  }, [props.searchInput.keyword]);

  const [locationInput, setLocationInput] = useState("");
  // const [locationResult, setLocationResult] = useState([]);

  useEffect(() => {
    setLocationInput(props.searchInput[props.id]);
  }, [props.searchInput, props.id]);

  const handleToggle = (filterItem) => {
    // const value = filterItem.tag
    // const newChecked = [...checked]
    // console.log("==== handleToggle ====")
    // console.log("checked", checked)
    // console.log("filterItem.checked === 0 & tempChecked.indexOf(value) !== -1", filterItem.checked === 0 && newChecked.indexOf(value) !== -1)

    // console.log("newChecked.indexOf(value)", newChecked.indexOf(value))

    // // make sure the checked list is consistent with filterInfo
    // if (filterItem.checked === 1 && newChecked.indexOf(value) === -1) {
    //     newChecked.push(value)
    //     console.log("filterItem.checked === 1 & currentIndex === -1")
    //     console.log("newChecked", newChecked)
    // }

    // if (filterItem.checked === 0 && newChecked.indexOf(value) !== -1) {
    //     newChecked.splice(newChecked.indexOf(value), 1)
    //     console.log("filterItem.checked === 0 & newChecked.indexOf(value) !== -1")
    //     console.log("newChecked", newChecked)
    // }

    // const currentIndex = newChecked.indexOf(value)
    // console.log("currentIndex", currentIndex)

    // if (currentIndex === -1 ) {
    //     newChecked.push(value)
    // } else {
    //     newChecked.splice(currentIndex, 1)
    //     console.log("newChecked.splice(currentIndex, 1)", newChecked)
    // }

    // setChecked(newChecked)
    //update the checked information into Parent Component
    props.handleFilters(filterItem);
  };

  const handleKeywordChange = (event) => {
    setKeywordInput(event.target.value.toLowerCase());
  };

  const handleKeywordClick = (event) => {
    event.preventDefault();
    props.handleKeywordSearch(keywordInput);
  };

  const searchKeyword = (
    <div
      className="search-filter-inputbox list-group-item "
      style={{ padding: 0 }}
    >
      <form>
        <div
          className="input-group"
          style={{ position: "relative", marginBottom: 2 }}
        >
          <input
            type="text"
            className="form-control list-group-item "
            style={{ border: "none", outline: "none", borderRadius: 0 }}
            placeholder="Search"
            value={keywordInput}
            onChange={handleKeywordChange}
          />
          <button
            className="list-group-item "
            style={{
              float: "right",
              border: "none",
              outline: "none",
              background: "none",
              right: 5,
            }}
            onClick={handleKeywordClick}
            type="submit"
          >
            <FaSearch></FaSearch>
          </button>
        </div>
      </form>
    </div>
  );

  const handleLocationChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setLocationInput(searchTerm);

    // props.handleLocSearch(props.id, searchTerm)
    handleLocationSearch(searchTerm);
  };

  // const handleLocationClick = event => {
  //     event.preventDefault()
  //     props.handleKeywordSearch(keywordInput)
  // };

  const searchLocation = (
    <div
      className="search-filter-inputbox list-group-item "
      style={{ padding: 0 }}
    >
      <div
        className="input"
        style={{ position: "relative", marginBottom: 2 }}
        width={100}
      >
        <input
          type="text"
          className="form-control list-group-item "
          style={{
            display: "inline-block",
            paddingLeft: 40,
            border: "none",
            outline: "none",
            borderRadius: 0,
          }}
          placeholder={"Search " + props.id}
          value={locationInput}
          onChange={handleLocationChange}
        />
        <LocationOnIcon sx={{ m: 1, left: 0, position: "absolute" }} />
      </div>
    </div>
  );

  const noOption = (
    <div className="list-group-item no-option">
      No tweets match this location
    </div>
  );

  const handleLocationSearch = (searchTerm) => {
    console.log("handleLocationSearch", searchTerm);
    if (searchTerm !== null) {
      const filterArray = filterList.filter((item) =>
        item.tag.toLowerCase().includes(searchTerm)
      );
      setLocationResult(filterArray);
    } else {
      setLocationResult(filterList);
    }
  };

  // useEffect( () => {
  //     console.log("checkbox filter", locationInput)
  //     handleLocationSearch(locationInput)

  // },[handleLocationSearch]);

  // const result = words.filter(word => word.length > 6);
  // const filterArray = filterList.filter((item) => (item.tag.toLowerCase().includes(locationInput)) )

  return (
    <div className="list-group option-wrapper" align="left">
      <div
        className="list-group-item list-group-item-action"
        style={{ textTransform: "uppercase", fontWeight: "bold" }}
      >
        {props.id === "state" ? "state/province" : props.id}
      </div>
      {props.id === "keyword" ? searchKeyword : searchLocation}
      <div
        className="show-partial"
        style={{ maxHeight: "380px", overflow: "auto" }}
      >
        <fieldset>
          {props.id === "keyword"
            ? // filterList.filter( item => item.category  === props.id).map( (filter, index) =>
              filterList.map((filter, index) => (
                <div key={index} className="checkbox">
                  <button
                    id={filter.tag}
                    onClick={() => handleToggle(filter)}
                    className="list-group-item list-group-item-action"
                    // disabled={filter.count === 0 ? true : false}
                  >
                    <p
                      className="label-text"
                      style={{ marginBottom: 0, textTransform: "capitalize" }}
                    >
                      {filter.tag}
                      <span className="count">
                        <span style={{ color: "#767676" }}>
                          {" "}
                          ({filter.count})
                        </span>
                      </span>
                      <span>
                        <Checkbox
                          sx={{ padding: 0, float: "right" }}
                          checked={filter.checked === 1 ? true : false}
                          // disabled={filter.count === 0 ? true : false}
                          inputProps={{ "aria-labelledby": filter.tag }}
                          disableRipple
                        />
                      </span>
                    </p>
                  </button>
                </div>
              ))
            : locationInput && locationResult.length === 0
            ? noOption
            : locationResult.map((filter, index) => (
                <div key={index} className="checkbox">
                  <button
                    id={filter.tag}
                    onClick={() => handleToggle(filter)}
                    className="list-group-item list-group-item-action"
                    // disabled={filter.count === 0 ? true : false}
                  >
                    <p
                      className="label-text"
                      style={{ marginBottom: 0, textTransform: "capitalize" }}
                    >
                      {filter.tag}
                      <span className="count">
                        <span style={{ color: "#767676" }}>
                          {" "}
                          ({filter.count})
                        </span>
                      </span>
                      <span>
                        <Checkbox
                          sx={{ padding: 0, float: "right" }}
                          checked={filter.checked === 1 ? true : false}
                          // disabled={filter.count === 0 ? true : false}
                          inputProps={{ "aria-labelledby": filter.tag }}
                          disableRipple
                        />
                      </span>
                    </p>
                  </button>
                </div>
              ))}
        </fieldset>
      </div>
    </div>
  );
}

export default FilterBox;
